<template>
  <Combobox v-slot="{ open }"
            as="div"
            class="relative"
            :multiple="multiple"
            :model-value="modelValue"
            @update:model-value="changeSelected"
  >
    <ComboboxButton class="flex items-center w-full px-6 py-2 overflow-hidden text-gray-200 transition bg-gray-300 rounded-full hover:text-gray-100">
      <div class="flex-shrink min-w-0 p-0 my-2 mr-2 overflow-hidden text-left bg-gray-300 border-none lg:mr-1 lg:h-6 ring-0 focus:ring-0 line-clamp-1">
        {{ buttonLabel }}
      </div>

      <IconChevronDown class="flex-shrink-0 w-6 h-6 ml-auto transition fill-current"
                       :class="open ? '-scale-y-100' : 'scale-y-100'"
      />
    </ComboboxButton>
    <TransitionRoot leave="transition ease-in duration-100"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
    >
      <ComboboxOptions class="absolute z-20 w-full mt-2 overflow-hidden text-gray-200 bg-gray-300 top-14 rounded-xl">
        <div class="py-2 overflow-auto max-h-96"
             data-lenis-prevent
        >
          <ComboboxOption v-if="!multiple"
                          class="px-6 py-2 transition cursor-default media-hover:hover:bg-gray-250 media-hover:hover:text-gray-100"
                          :value="null"
          >
            {{ placeholder }}
          </ComboboxOption>
          <ComboboxOption v-for="item in items"
                          :key="item.key"
                          :value="item"
                          class="px-6 py-2 transition cursor-default media-hover:hover:bg-gray-250 media-hover:hover:text-gray-100"
                          :class="getOptionClass(item)"
          >
            {{ item.label }}
          </ComboboxOption>
        </div>
      </ComboboxOptions>
    </TransitionRoot>
  </Combobox>
</template>

<script setup lang="ts">
import { Combobox, ComboboxOptions, ComboboxOption, ComboboxButton, TransitionRoot } from '@headlessui/vue'
import type { ComboboxItem } from '../models/combobox'

type DropdownProps = {
  items: Array<ComboboxItem>
  placeholder: string
  modelValue: ComboboxItem | Array<ComboboxItem>
  multiple?: boolean
}

const props = withDefaults(defineProps<DropdownProps>(), {
  multiple: false
})

const emit = defineEmits<{
  'update:model-value': [item: DropdownProps['modelValue']]
}>()

const buttonLabel = computed(() => {
  const isModelValueArray = Array.isArray(props.modelValue)

  if (!props.modelValue || (isModelValueArray && !props.modelValue.length)) {
    return props.placeholder
  }

  if (isModelValueArray) {
    return props.modelValue.map(item => item.label).join(', ')
  }

  return props.modelValue.label
})

function getOptionClass(item: ComboboxItem) {
  const isModelValueArray = Array.isArray(props.modelValue)

  if (
    (isModelValueArray && props.modelValue?.some(i => i.key === item.key))
    || (!isModelValueArray && props.modelValue?.key === item.key)
  ) {
    return 'bg-gray-250'
  }

  return ''
}

function changeSelected(item: DropdownProps['modelValue']) {
  emit('update:model-value', item)
}
</script>
